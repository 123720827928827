var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeUserInfo
    ? _c(
        "div",
        { staticClass: "the-navbar__user-meta flex items-center" },
        [
          _c(
            "div",
            { staticClass: "text-right leading-tight hidden sm:block" },
            [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.activeUserInfo))
              ]),
              _c("small", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(_vm.resources.Available.i18n) ||
                        _vm.resources.Available.name
                    ) +
                    "\n    "
                )
              ])
            ]
          ),
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: { "vs-custom-content": "", "vs-trigger-click": "" }
            },
            [
              _c("div", { staticClass: "con-img ml-3" }, [
                _vm.activeUserImg
                  ? _c("img", {
                      key: "onlineImg",
                      staticClass:
                        "rounded-full shadow-md cursor-pointer block",
                      attrs: {
                        src: _vm.activeUserImg,
                        alt: "user-img",
                        width: "40",
                        height: "40"
                      },
                      on: { error: _vm.imageUrlAlt }
                    })
                  : _vm._e()
              ]),
              _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                _c(
                  "ul",
                  { staticStyle: { "min-width": "9rem" } },
                  [
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: {
                          click: function($event) {
                            _vm.$router
                              .push("/pages/profile")
                              .catch(function() {})
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "UserIcon", svgClasses: "w-4 h-4" }
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.Profile.i18n) ||
                                  _vm.resources.Profile.name
                              ) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    ),
                    _c("vs-divider", { staticClass: "m-1" }),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: { click: _vm.logout }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" }
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.Logout.i18n) ||
                                  _vm.resources.Logout.name
                              ) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }