var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-dropdown",
    {
      staticClass: "cursor-pointer mr-4",
      attrs: { "vs-custom-content": "", "vs-trigger-click": "" }
    },
    [
      _c(
        "span",
        { staticClass: "cursor-pointer flex items-center i18n-locale" },
        [
          _c("img", {
            staticClass: "h-4 w-5",
            attrs: { src: _vm.i18n_locale_img, alt: _vm.$i18n.locale }
          }),
          _c("span", { staticClass: "hidden sm:block ml-2" }, [
            _vm._v(_vm._s(_vm.getCurrentLocaleData.lang))
          ])
        ]
      ),
      _c(
        "vs-dropdown-menu",
        { staticClass: "w-48 i18n-dropdown vx-navbar-dropdown" },
        [
          _c(
            "vs-dropdown-item",
            {
              on: {
                click: function($event) {
                  return _vm.updateLocale("en")
                }
              }
            },
            [
              _c("img", {
                staticClass: "h-4 w-5 mr-1",
                attrs: {
                  src: require("@/assets/images/flags/en.png"),
                  alt: "en"
                }
              }),
              _vm._v("\n       English\n    ")
            ]
          ),
          _c(
            "vs-dropdown-item",
            {
              on: {
                click: function($event) {
                  return _vm.updateLocale("es")
                }
              }
            },
            [
              _c("img", {
                staticClass: "h-4 w-5 mr-1",
                attrs: {
                  src: require("@/assets/images/flags/es.png"),
                  alt: "es"
                }
              }),
              _vm._v("\n       Español\n    ")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }