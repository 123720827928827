/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
    i18n: "Home"
  },
  {
    url: "/industry",
    name: "Access to platforms",
    slug: "Industries",
    icon: "LogInIcon",
    i18n: "AccessToPlatforms"
  },
  {
    url: null,
    name: "Purchases",
    icon: "ShoppingCartIcon",
    i18n: "Purchases",
    submenu: [
      {
        url: "/purchases/index",
        name: "List",
        slug: "List",
        i18n: "List"
      },
      {
        url: "/purchases/affiliations",
        name: "Affiliations",
        slug: "Affiliations",
        i18n: "Affiliations",
      }
    ]
  },
  {
    url: null,
    name: "Genealogy",
    icon: "GitPullRequestIcon",
    i18n: "Genealogy",
    submenu: [
      {
        url: "/genealogy/index",
        name: "Binary tree",
        slug: "binaryTree",
        i18n: "BinaryTree",
      },
      {
        url: "/genealogy/detail",
        name: "Detail",
        slug: "detail",
        i18n: "Detail",
      },
      {
        url: "/orders/index",
        name: "Orders",
        slug: "orders",
        i18n: "Orders",
      },
      {
        url: "/genealogy/statusByPeriod",
        name: "Status by period",
        slug: "statusByPeriod",
        i18n: "StatusByPeriod",
      },
    ]
  },
  {
    url: null,
    name: "Commissions",
    icon: "ActivityIcon",
    i18n: "Commissions",
    submenu: [
      {
        url: "/commissions/Index",
        name: "Total",
        slug: "Total",
        i18n: "Total",
      },
      {
        url: "/commissions/NewCustomized",
        name: "Customized",
        slug: "Customized",
        i18n: "Customized",
      },
    ],
  },
  {
    url: null,
    name: "Tourism",
    icon: "SunIcon",
    i18n: "Tourism",
    submenu: [
      // {
      //   url: "/bookings/summary",
      //   name: "Summary",
      //   slug: "Summary",
      //   i18n: "Summary",
      // },
      {
        url: "/bookings/Index",
        name: "List",
        slug: "List",
        i18n: "List",
      },
      {
        url: "/bookings/customer",
        name: "Customers",
        slug: "Customers",
        i18n: "Customers",
      },
    ],
  },
  {
    url: null,
    name: "BlockPay",
    icon: "DollarSignIcon",
    submenu: [
      {
        url: "/blockPay/index",
        name: "List",
        slug: "List",
        i18n: "List",
      },
    ],
  },
  {
    url: null,
    name: "Resources",
    icon: "ArchiveIcon",
    i18n: "Resources",
    submenu: [
      {
        url: "/resources/news",
        name: "News",
        slug: "News",
        i18n: "News",
      },
      {
        url: "/resources/faq",
        name: "FAQs",
        slug: "FAQs",
        i18n: "FAQs",
      },
      {
        url: "/resources/download",
        name: "Downlads",
        slug: "Downlads",
        i18n: "Downlads",
      },
      {
        url: "/resources/exchangeRate",
        name: "Exchange Rate",
        slug: "ExchangeRate",
        i18n: "ExchangeRate",
      },
    ],
  }
];
