var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "theme-customizer" } },
    [
      _c("vs-button", {
        staticClass: "customizer-btn",
        attrs: {
          color: "primary",
          type: "filled",
          "icon-pack": "feather",
          icon: "icon-settings"
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.active = !_vm.active
          }
        }
      }),
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            "click-not-close": "",
            "hidden-background": "",
            "position-right": ""
          },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c(
            "div",
            { staticClass: "h-full" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "customizer-header mt-6 flex items-center justify-between px-6"
                },
                [
                  _c("div", [
                    _c("h4", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.resources.ThemeCustomized.i18n) ||
                              _vm.resources.ThemeCustomized.name
                          ) +
                          "\n          "
                      )
                    ]),
                    _c("small", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.alerts.MsgCustomizeTheme.i18n) ||
                              _vm.alerts.MsgCustomizeTheme.name
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _c("feather-icon", {
                    staticClass: "cursor-pointer",
                    attrs: { icon: "XIcon" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.active = false
                      }
                    }
                  })
                ],
                1
              ),
              _c("vs-divider", { staticClass: "mb-0" }),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  tag: "component",
                  staticClass: "scroll-area--customizer pt-4 pb-6",
                  attrs: { settings: _vm.settings }
                },
                [
                  _c(
                    "div",
                    { staticClass: "px-6" },
                    [
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.LayoutType.i18n) ||
                                  _vm.resources.LayoutType.name
                              ) +
                              "\n            "
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "vertical",
                                  "vs-name": "layout-type-vertical"
                                },
                                model: {
                                  value: _vm.layoutType,
                                  callback: function($$v) {
                                    _vm.layoutType = $$v
                                  },
                                  expression: "layoutType"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Vertical.i18n) ||
                                        _vm.resources.Vertical.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "horizontal",
                                  "vs-name": "layout-type-horizontal"
                                },
                                model: {
                                  value: _vm.layoutType,
                                  callback: function($$v) {
                                    _vm.layoutType = $$v
                                  },
                                  expression: "layoutType"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Horizontal.i18n) ||
                                        _vm.resources.Horizontal.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("vs-divider"),
                      _c("div", [
                        _c("h5", { staticClass: "mb-4" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.Color.i18n) ||
                                  _vm.resources.Color.name
                              ) +
                              "\n            "
                          )
                        ]),
                        _c(
                          "ul",
                          { staticClass: "clearfix" },
                          [
                            _vm._l(_vm.themeColors, function(color) {
                              return _c("li", {
                                key: color,
                                staticClass:
                                  "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                class: {
                                  "shadow-outline": color == _vm.primaryColor
                                },
                                style: { backgroundColor: color },
                                on: {
                                  click: function($event) {
                                    return _vm.updatePrimaryColor(color)
                                  }
                                }
                              })
                            }),
                            _c("li", {
                              staticClass:
                                "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                              class: {
                                "shadow-outline":
                                  _vm.customPrimaryColor == _vm.primaryColor
                              },
                              style: {
                                backgroundColor: _vm.customPrimaryColor
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updatePrimaryColor(
                                    _vm.customPrimaryColor
                                  )
                                }
                              }
                            }),
                            _c("li", { staticClass: "float-left" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customPrimaryColor,
                                    expression: "customPrimaryColor"
                                  }
                                ],
                                staticClass:
                                  "w-10 cursor-pointer h-10 rounded-lg m-2",
                                attrs: { type: "color" },
                                domProps: { value: _vm.customPrimaryColor },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.customPrimaryColor = $event.target.value
                                  }
                                }
                              })
                            ])
                          ],
                          2
                        )
                      ]),
                      _c("vs-divider"),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.Color.i18n) ||
                                  _vm.resources.Color.name
                              ) +
                              "\n            "
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "light",
                                  "vs-name": "theme-mode-light"
                                },
                                model: {
                                  value: _vm.themeMode,
                                  callback: function($$v) {
                                    _vm.themeMode = $$v
                                  },
                                  expression: "themeMode"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Light.i18n) ||
                                        _vm.resources.Light.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "dark",
                                  "vs-name": "theme-mode-dark"
                                },
                                model: {
                                  value: _vm.themeMode,
                                  callback: function($$v) {
                                    _vm.themeMode = $$v
                                  },
                                  expression: "themeMode"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Dark.i18n) ||
                                        _vm.resources.Dark.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm.layoutType === "vertical"
                              ? _c(
                                  "vs-radio",
                                  {
                                    attrs: {
                                      "vs-value": "semi-dark",
                                      "vs-name": "theme-mode-semi-dark"
                                    },
                                    model: {
                                      value: _vm.themeMode,
                                      callback: function($$v) {
                                        _vm.themeMode = $$v
                                      },
                                      expression: "themeMode"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(_vm.resources.SemiDark.i18n) ||
                                            _vm.resources.SemiDark.name
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c("vs-divider"),
                      _vm.layoutType === "vertical"
                        ? [
                            _c(
                              "div",
                              { staticClass: "mt-4 flex justify-between" },
                              [
                                _c("h5", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.CollapseSidebar.i18n
                                        ) || _vm.resources.CollapseSidebar.name
                                      ) +
                                      "\n              "
                                  )
                                ]),
                                _c("vs-switch", {
                                  model: {
                                    value: _vm.reduced_sidebar,
                                    callback: function($$v) {
                                      _vm.reduced_sidebar = $$v
                                    },
                                    expression: "reduced_sidebar"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("vs-divider")
                          ]
                        : _vm._e(),
                      _vm.layoutType === "vertical"
                        ? [
                            _c("div", { staticClass: "mt-4" }, [
                              _c("h5", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.NavbarColor.i18n) ||
                                        _vm.resources.NavbarColor.name
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _c(
                                "ul",
                                { staticClass: "clearfix" },
                                [
                                  _c("li", {
                                    staticClass:
                                      "w-10 m-2 h-10 rounded-lg float-left cursor-pointer border border-solid d-theme-border-grey-light",
                                    class: _vm.navbarColorOptionClasses(
                                      _vm.navbarColorInitial
                                    ),
                                    style: {
                                      background: _vm.navbarColorInitial
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.navbarColorLocal =
                                          _vm.navbarColorInitial
                                      }
                                    }
                                  }),
                                  _vm._l(_vm.themeColors, function(color) {
                                    return _c("li", {
                                      key: color,
                                      staticClass:
                                        "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                      class: _vm.navbarColorOptionClasses(
                                        color
                                      ),
                                      style: { backgroundColor: color },
                                      on: {
                                        click: function($event) {
                                          _vm.navbarColorLocal = color
                                        }
                                      }
                                    })
                                  }),
                                  _c("li", {
                                    staticClass:
                                      "w-10 cursor-pointer h-10 rounded-lg m-2 float-left",
                                    class: _vm.navbarColorOptionClasses(
                                      _vm.navbarColorOptionClasses
                                    ),
                                    style: {
                                      backgroundColor: _vm.customNavbarColor
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.navbarColorLocal =
                                          _vm.customNavbarColor
                                      }
                                    }
                                  }),
                                  _c("li", { staticClass: "float-left" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.customNavbarColor,
                                          expression: "customNavbarColor"
                                        }
                                      ],
                                      staticClass:
                                        "w-10 cursor-pointer h-10 rounded-lg m-2",
                                      attrs: { type: "color" },
                                      domProps: {
                                        value: _vm.customNavbarColor
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.customNavbarColor =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ])
                                ],
                                2
                              )
                            ]),
                            _c("vs-divider")
                          ]
                        : _vm._e(),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.NavbarType.i18n) ||
                                  _vm.resources.NavbarType.name
                              ) +
                              "\n            "
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _vm.layoutType === "vertical" ||
                            _vm.windowWidth < 1200
                              ? _c(
                                  "vs-radio",
                                  {
                                    staticClass: "mr-4",
                                    attrs: {
                                      "vs-value": "hidden",
                                      "vs-name": "navbar-type-hidden"
                                    },
                                    model: {
                                      value: _vm.navbarTypeLocal,
                                      callback: function($$v) {
                                        _vm.navbarTypeLocal = $$v
                                      },
                                      expression: "navbarTypeLocal"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(_vm.resources.Hidden.i18n) ||
                                            _vm.resources.Hidden.name
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "static",
                                  "vs-name": "navbar-type-static"
                                },
                                model: {
                                  value: _vm.navbarTypeLocal,
                                  callback: function($$v) {
                                    _vm.navbarTypeLocal = $$v
                                  },
                                  expression: "navbarTypeLocal"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Static.i18n) ||
                                        _vm.resources.Static.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "sticky",
                                  "vs-name": "navbar-type-sticky"
                                },
                                model: {
                                  value: _vm.navbarTypeLocal,
                                  callback: function($$v) {
                                    _vm.navbarTypeLocal = $$v
                                  },
                                  expression: "navbarTypeLocal"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Sticky.i18n) ||
                                        _vm.resources.Sticky.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-value": "floating",
                                  "vs-name": "navbar-type-floating"
                                },
                                model: {
                                  value: _vm.navbarTypeLocal,
                                  callback: function($$v) {
                                    _vm.navbarTypeLocal = $$v
                                  },
                                  expression: "navbarTypeLocal"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Floating.i18n) ||
                                        _vm.resources.Floating.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("vs-divider"),
                      _c("div", { staticClass: "mt-4" }, [
                        _c("h5", { staticClass: "mb-2" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.FooterType.i18n) ||
                                  _vm.resources.FooterType.name
                              ) +
                              "\n            "
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "hidden",
                                  "vs-name": "footer-type-hidden"
                                },
                                model: {
                                  value: _vm.footerTypeLocal,
                                  callback: function($$v) {
                                    _vm.footerTypeLocal = $$v
                                  },
                                  expression: "footerTypeLocal"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Hidden.i18n) ||
                                        _vm.resources.Hidden.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  "vs-value": "static",
                                  "vs-name": "footer-type-static"
                                },
                                model: {
                                  value: _vm.footerTypeLocal,
                                  callback: function($$v) {
                                    _vm.footerTypeLocal = $$v
                                  },
                                  expression: "footerTypeLocal"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Static.i18n) ||
                                        _vm.resources.Static.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "vs-radio",
                              {
                                attrs: {
                                  "vs-value": "sticky",
                                  "vs-name": "footer-type-sticky"
                                },
                                model: {
                                  value: _vm.footerTypeLocal,
                                  callback: function($$v) {
                                    _vm.footerTypeLocal = $$v
                                  },
                                  expression: "footerTypeLocal"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Sticky.i18n) ||
                                        _vm.resources.Sticky.name
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("vs-divider"),
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c("h5", { staticClass: "mb-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(_vm.resources.Animation.i18n) ||
                                    _vm.resources.Animation.name
                                ) +
                                " -\n              " +
                                _vm._s(_vm.routerTransitionLocal) +
                                "\n            "
                            )
                          ]),
                          _c(
                            "vs-select",
                            {
                              model: {
                                value: _vm.routerTransitionLocal,
                                callback: function($$v) {
                                  _vm.routerTransitionLocal = $$v
                                },
                                expression: "routerTransitionLocal"
                              }
                            },
                            _vm._l(_vm.routerTransitionsList, function(
                              item,
                              index
                            ) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item.value, text: item.text }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }