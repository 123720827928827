<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="layout--main"
    :class="[
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      { 'no-scroll': isAppPage }
    ]"
  >
    <the-customizer
      v-if="!disableCustomizer"
      :footerType="footerType"
      :hideScrollToTop="hideScrollToTop"
      :navbarType="navbarType"
      :navbarColor="navbarColor"
      :routerTransition="routerTransition"
      @toggleHideScrollToTop="toggleHideScrollToTop"
      @updateFooter="updateFooter"
      @updateNavbar="updateNavbar"
      @updateNavbarColor="updateNavbarColor"
      @updateRouterTransition="updateRouterTransition"
    />

    <v-nav-menu
      :navMenuItems="navMenuItems"
      title="VISIONBIZ"
      parent=".layout--main"
    />

    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay" />

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :navbarType="navbarType"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark }
          ]"
        />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <h-nav-menu
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark }
          ]"
          :navMenuItems="navMenuItems"
        />
      </template>

      <template v-else>
        <the-navbar-vertical
          :navbarColor="navbarColor"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark }
          ]"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">
        <div class="router-view">
          <div class="router-content">
            <transition :name="routerTransition">
              <div
                v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
                class="router-header flex flex-wrap items-center mb-6"
              >
                <div
                  class="content-area__heading"
                  :class="{
                    'pr-4 border-0 md:border-r border-solid border-grey-light':
                      $route.meta.breadcrumb
                  }"
                >
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb
                  class="ml-4 md:block hidden"
                  v-if="$route.meta.breadcrumb"
                  :route="$route"
                  :isRTL="$vs.rtl"
                />

                <!-- DROPDOWN -->
                <vs-dropdown
                  vs-trigger-click
                  class="ml-auto md:block hidden cursor-pointer"
                >
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div
                        @click="$router.push('/pages/profile').catch(() => {})"
                        class="flex items-center"
                      >
                        <feather-icon
                          icon="UserIcon"
                          class="inline-block mr-2"
                          svgClasses="w-4 h-4"
                        />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div
                        @click="$router.push('/apps/todo').catch(() => {})"
                        class="flex items-center"
                      >
                        <feather-icon
                          icon="CheckSquareIcon"
                          class="inline-block mr-2"
                          svgClasses="w-4 h-4"
                        />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div
                        @click="$router.push('/apps/email').catch(() => {})"
                        class="flex items-center"
                      >
                        <feather-icon
                          icon="MailIcon"
                          class="inline-block mr-2"
                          svgClasses="w-4 h-4"
                        />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </transition>

            <div class="content-area__content">
              <back-to-top
                bottom="5%"
                :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
                visibleoffset="500"
                v-if="!hideScrollToTop"
              >
                <vs-button
                  icon-pack="feather"
                  icon="icon-arrow-up"
                  class="shadow-lg btn-back-to-top"
                />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view
                  @changeRouteTitle="changeRouteTitle"
                  @setAppClasses="
                    classesStr => $emit('setAppClasses', classesStr)
                  "
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>

<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
import navMenuItems from "@/layouts/components/vertical-nav-menu/navMenuItems.js";
import TheCustomizer from "@/layouts/components/customizer/TheCustomizer.vue";
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal.vue";
import TheNavbarVertical from "@/layouts/components/navbar/TheNavbarVertical.vue";
import TheFooter from "@/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import VNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import database from "@/assets/resources/enums/database.json";
import axios from "axios";

const VxTour = () => import("@/components/VxTour.vue");

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    VxTour
  },
  data() {
    return {
      database: database,
      contactType: JSON.parse(localStorage.getItem("userInfo")).contactType,
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || "#fff",
      navbarType: themeConfig.navbarType || "floating",
      navMenuItems: navMenuItems,
      navMenu: [
        {
          url: "/",
          name: "Home",
          slug: "home",
          icon: "HomeIcon",
          i18n: "Home"
        },
        {
          url: "/industry",
          name: "Access to platforms",
          slug: "Industries",
          icon: "LogInIcon",
          i18n: "AccessToPlatforms"
        },
        {
          url: null,
          name: "Purchases",
          icon: "ShoppingCartIcon",
          i18n: "Purchases",
          submenu: [
            {
              url: "/purchases/index",
              name: "List",
              slug: "List",
              i18n: "List"
            },
            {
              url: "/purchases/affiliations",
              name: "Affiliations",
              slug: "Affiliations",
              i18n: "Affiliations"
            }
          ]
        },
        {
          url: null,
          name: "Genealogy",
          icon: "GitPullRequestIcon",
          i18n: "Genealogy",
          submenu: [
            {
              url: "/genealogy/index",
              name: "Binary tree",
              slug: "binaryTree",
              i18n: "BinaryTree"
            },
            {
              url: "/genealogy/detail",
              name: "Detail",
              slug: "detail",
              i18n: "Detail"
            },
            {
              url: "/orders/index",
              name: "Orders",
              slug: "orders",
              i18n: "Orders"
            },
            {
              url: "/genealogy/statusByPeriod",
              name: "Status by period",
              slug: "statusByPeriod",
              i18n: "StatusByPeriod"
            }
          ]
        },
        {
          url: null,
          name: "Commissions",
          icon: "ActivityIcon",
          i18n: "Commissions",
          submenu: [
            {
              url: "/commissions/Index",
              name: "Total",
              slug: "Total",
              i18n: "Total"
            },
            {
              url: "/commissions/NewCustomized",
              name: "Customized",
              slug: "Customized",
              i18n: "Customized"
            }
          ]
        },
        {
          url: null,
          name: "Tourism",
          icon: "SunIcon",
          i18n: "Tourism",
          submenu: [
            // {
            //   url: "/bookings/summary",
            //   name: "Summary",
            //   slug: "Summary",
            //   i18n: "Summary"
            // },
            {
              url: "/bookings/Index",
              name: "List",
              slug: "List",
              i18n: "List"
            },
            {
              url: "/bookings/customer",
              name: "Customers",
              slug: "Customers",
              i18n: "Customers"
            }
          ]
        },
        {
          url: null,
          name: "BlockPay",
          icon: "DollarSignIcon",
          submenu: [
            {
              url: "/blockPay/index",
              name: "List",
              slug: "List",
              i18n: "List"
            }
          ]
        },
        {
          url: null,
          name: "Resources",
          icon: "ArchiveIcon",
          i18n: "Resources",
          submenu: [
                        {
              url: "/resources/marketing",
              name: "Marketing",
              slug: "Marketing",
              i18n: "Marketing"
            },
            {
              url: "/resources/news",
              name: "News",
              slug: "News",
              i18n: "News"
            },
            {
              url: "/resources/faq",
              name: "FAQs",
              slug: "FAQs",
              i18n: "FAQs"
            },
            {
              url: "/resources/download",
              name: "Downloads",
              slug: "Downloads",
              i18n: "Downloads"
            },
            {
              url: "/resources/exchangeRate",
              name: "Exchange Rate",
              slug: "ExchangeRate",
              i18n: "ExchangeRate"
            }
          ]
        },
        {
          url: null,
          name: "Landings",
          icon: "BookIcon",
          submenu: [
            {
              url: "/landing/prospects",
              name: "Prospects",
              slug: "Prospects",
              i18n: "Prospects"
            },
            {
              url: "/landing/management",
              name: "Management",
              slug: "Management",
              i18n: "Management"
            }
          ]
        }
      ],
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val);
    }
  },
  computed: {
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth == "default")
          return "content-area-reduced";
        else if (this.verticalNavMenuWidth == "reduced")
          return "content-area-lg";
        else return "content-area-full";
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      else return "content-area-full";
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static"
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme == "dark";
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating"
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val == "#fff") this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === "horizontal" && this.windowWidth >= 1200) ||
        (layoutType === "vertical" && this.windowWidth < 1200)
      ) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },
    updateNavbar(val) {
      if (val == "static")
        this.updateNavbarColor(this.isThemeDark ? "#10163a" : "#fff");
      this.navbarType = val;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },

    // async getIboAllow() {
    //   await axios({
    //     method: "GET",
    //     url: `${
    //       process.env.VUE_APP_APIVT
    //     }ParameterAplication/${this.$i18n.locale.toUpperCase()}`,
    //     headers: {
    //       "content-type": "application/json",
    //       Authorization: "bearer " + localStorage.getItem("tokenVT")
    //     }
    //   }).then(
    //     async result => {
    //       if (result.data.length > 0) {
    //         let _user = JSON.parse(localStorage.getItem("userInfo"));
    //         let _list =
    //           (result.data.find(x => x.parameter.name == "CanGetBooking").value).split(",");

    //         let _allow = _list.filter(x => x == _user.itaCode);

    //         if (_allow.length > 0) {
    //           this.navMenu[5].submenu.push({
    //             url: "/bookings/index-managed",
    //             name: "My management",
    //             slug: "My management",
    //             i18n: "MyManagement"
    //           },
    //           {
    //             url: "/bookings/byReference",
    //             name: "Get",
    //             slug: "Get",
    //             i18n: "Get"
    //           },
    //           {
    //             url: "/bookings/create",
    //             name: "Create",
    //             slug: "Create",
    //             i18n: "Create"
    //           },
    //           {
    //             url: "/bookings/incentive-commissions",
    //             name: "Incentives",
    //             slug: "Incentives",
    //             i18n: "Incentives"
    //           },
    //           );
    //         }
    //       }
    //     },
    //     error => {
    //       this.$vs.notify({
    //         title: this.$i18n.t("Error"),
    //         text: this.$i18n.t("MsgError") + "<br/>" + error.message,
    //         color: "danger",
    //         iconPack: "feather",
    //         position: "top-right",
    //         icon: "icon-x-circle"
    //       });
    //     }
    //   );
    // },

    async getLyraAccess() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ParameterAplication/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        async result => {
          if (result.data.length > 0) {
            let _user = JSON.parse(localStorage.getItem("userInfo"));
            let _list =
              (result.data.find(x => x.parameter.name == "CanViewLyra").value).split(",");

            let _allow = _list.filter(x => x == _user.itaCode);

            if (_allow.length > 0) {
              this.navMenu[3].submenu.push({
                url: "/genealogy/new-binary",
                name: "Lyra binary tree",
                slug: "LyraBinaryTree",
                i18n: "LyraBinaryTree"
              }
              );
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  async created() {
    let contactType = JSON.parse(localStorage.getItem("userInfo")).contactType;

    const color =
      this.navbarColor == "#fff" && this.isThemeDark
        ? "#10163a"
        : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    if (contactType == this.database.contactType.ClientIta) {
      this.navMenu[1].submenu.splice(1, 2);
      this.navMenu.splice(2);
    }

    if (
      localStorage.getItem("status") != "1" &&
      contactType == this.database.contactType.Ita
    ) {
      this.navMenu[3].submenu.splice(0, 2);
      this.navMenu[3].submenu.splice(1, 2);
    }

    let haveNeo = this.navMenu.filter(x => x.name == "Neo");
    if (
      localStorage.getItem("haveNeo") != "0" &&
      localStorage.getItem("haveNeo") != null &&
      haveNeo.length == 0 &&
      contactType == this.database.contactType.Ita
    ) {
      this.navMenu.push({
        url: "/tracer/index",
        name: "Neo",
        icon: "MousePointerIcon",
        submenu: [
          {
            url: "/tracer/summary",
            name: "Summary",
            slug: "Summary",
            i18n: "Summary"
          }
        ]
      });
    }

    // await this.getIboAllow();
    await this.getLyraAccess();

    this.navMenuItems = this.navMenu;
    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch(
      "$store.state.windowWidth",
      val => {
        if (val < 1200) {
          this.disableThemeTour = true;
          this.dynamicWatchers.windowWidth();
        }
      }
    );

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
      "$store.state.verticalNavMenuWidth",
      () => {
        this.disableThemeTour = true;
        this.dynamicWatchers.verticalNavMenuWidth();
      }
    );

    this.dynamicWatchers.rtl = this.$watch("$vs.rtl", () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });
  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map(i => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  }
};
</script>
